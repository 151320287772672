<template>
    <svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   width="20"
   height="16"
   viewBox="0 0 5.2916665 4.2333335"
   version="1.1"
   id="svg8"
   inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
   sodipodi:docname="hamburger.svg">
  <defs
     id="defs2" />
  <sodipodi:namedview
     id="base"
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1.0"
     inkscape:pageopacity="0.0"
     inkscape:pageshadow="2"
     inkscape:zoom="5.6"
     inkscape:cx="6.7417728"
     inkscape:cy="-7.3760287"
     inkscape:document-units="mm"
     inkscape:current-layer="layer1"
     showgrid="false"
     inkscape:window-width="1920"
     inkscape:window-height="1057"
     inkscape:window-x="1072"
     inkscape:window-y="642"
     inkscape:window-maximized="1"
     showguides="false"
     units="px" />
  <metadata
     id="metadata5">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title></dc:title>
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <g
     inkscape:label="Layer 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(0,-292.76665)">
    <g
       id="g862"
       transform="matrix(0.05138285,0,0,0.05402984,-2.7493659,289.66733)">
      <rect
         ry="5.4964595"
         y="57.363094"
         x="53.507465"
         height="13.828559"
         width="102.98507"
         id="rect815"
         style="fill:#4d4d4d;fill-opacity:1;stroke:none;stroke-width:9.69630432;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:stroke fill markers" />
      <rect
         ry="5.49646"
         y="89.624702"
         x="53.507462"
         height="13.828559"
         width="102.98508"
         id="rect815-9"
         style="fill:#4d4d4d;fill-opacity:1;stroke:none;stroke-width:9.69630432;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:stroke fill markers" />
      <rect
         ry="5.49646"
         y="121.88631"
         x="53.507462"
         height="13.828559"
         width="102.98508"
         id="rect815-5"
         style="fill:#4d4d4d;fill-opacity:1;stroke:none;stroke-width:9.69630432;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:stroke fill markers" />
    </g>
  </g>
</svg>
</template>
